.page-header {
  margin-bottom: 1rem;
  padding: 1rem;

  .breadcrumbs {
    color: rgba(0, 0, 0, 0.6);
  }

  h2 {
    margin-top: 0.25rem;
    margin-bottom: 0;
    line-height: 2rem;
    font-weight: 400;
    color: $primary-main;
  }

  .MuiButton-root {
    width: 100%;
    white-space: nowrap;
  }

  @media screen and (min-width: 48em) {
    .MuiGrid-item:last-child {
      flex-grow: 0;
      display: flex;
      align-items: flex-end;
    }
  }
}
