// mixins

// variables
$white: #ffffff;
$black: #000000;
$light-grey: #bdbdbd;
$primary-dark: #0b183e;
$primary-main: #4165f4;
$primary-faint: #ebf1ff;
$blue-gray-50: #eceff1;
$blue-gray-300: #e0e0e0;
$error-main: #f44336;

$et-blue-900: #0b183e;
$grey-400: #bdbdbd;
$success-main: #4caf50;

//text-related variables
$text-light-mode-primary: rgba(0, 0, 0, 0.87);
$text-primary-dark: #162e7c;
$summary-base-card-width: 45rem;
$text-light-mode-secondary: rgba(0, 0, 0, 0.6);

//Heights
$top-nav-height: 7rem;

$breakpoint-mobile: 48em;
