.checkout-page-layout {
  &__main {
    main {
      height: 100%;
    }
    .main-content {
      @media screen and (max-width: $breakpoint-mobile) {
        height: 100vh;
      }

      height: 100%;
    }
  }
  &__image {
    background: $primary-faint;
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    position: relative;
    opacity: 1;
    height: 100vh;
  }
  &__rhs-container {
    padding: 2.5rem;
    height: 100%;

    &__text {
      color: $text-primary-dark;
    }

    &__avatar {
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
