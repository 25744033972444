.card-table {
  &__pagination {
    margin-top: 1.5rem;
  }

  &__form {
    height: 7.25rem;

    @media screen and (max-width: $breakpoint-mobile) {
      height: 17rem;
    }

    background: $white;
  }
  &__filters {
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);
  }
  &__link {
    color: $primary-main;
  }
}
