.button {
  &__container {
    &__large {
      &.MuiButton-root {
        margin: 0 2.5rem 0 0;
      }
    }
  }
  &__large {
    &.MuiButton-root {
      height: 3rem;
      @media screen and (min-width: 48em) {
        height: 2.625rem;
        width: 12.813rem;
        padding: 0.5rem 1rem;
      }
    }
  }
  &__non-clickable {
    cursor: default;
    pointer-events: none;
  }

  &__success {
    &.MuiButton-root {
      background-color: $success-main;
    }
  }
}
