.home-page-layout {
  height: 100vh;

  main {
    flex-grow: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    margin-top: calc($top-nav-height);

    @media screen and (min-width: 48em) {
      margin-top: calc($top-nav-height);
    }

    .main-content {
      flex: 1;
    }

    .copyright {
      text-align: center;
      margin-top: 2rem;
      padding: 1rem;
      font-size: 0.75rem;
    }
  }

  &__top-nav {
    background: $white;
    &__avatar {
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__tabs {
      align-self: flex-end;
      @media screen and (max-width: 48em) {
        margin-left: 1rem;
      }
    }

    // Desktop Styles
    @media screen and (min-width: 48em) {
      .MuiToolbar-root {
        height: $top-nav-height;
        padding-bottom: 1rem;
      }
    }
  }
}
