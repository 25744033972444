
    @use "sass:color";
    @use "styles/palette" as palette;
    @use "styles/variables" as variables;
    
// Global Variables and Mixins
@import 'globals';

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
}

// App Styles
#__next {
  height: 100vh;

  @import './components/spaces/SpaceCardTable';
  @import './components/common/PageHeader.scss';

  @import './components/common/BaseHookForm.scss';
  @import './pages/SpaceShowPage.scss';

  @import './components/layout/HomePageLayout';
  @import './components/layout/CheckoutPageLayout';
}

@import 'Button';
